import { TabsTrigger } from '@radix-ui/react-tabs';
import { ProjectTab } from '@/types';

function Tab({ value, icon: Icon, label, currentTheme, activeTab }: ProjectTab) {
  return (
    <TabsTrigger
      value={value}
      style={{
        backgroundColor: currentTheme?.background,
        color: currentTheme?.['primary-text'],
      }}
      className="!shadow-none transition-all ease-in-out duration-300 w-52 max1200:w-40 font-bold text-base relative py-1.5"
    >
      <div className="flex items-center gap-1.5 text-center justify-center">
        <Icon style={{ color: currentTheme?.primary }} className="size-5" />
        <p className="epilogue-font">{label}</p>
      </div>
      <hr
        style={{ backgroundColor: currentTheme?.primary }}
        className={`absolute -bottom-3.5 w-full transition-all ease-in-out duration-300 h-1 ${
          activeTab === value ? 'opacity-100' : 'opacity-0'
        }`}
      />
    </TabsTrigger>
  );
}

export default Tab;
