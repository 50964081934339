/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  IconAdjustmentsHorizontal,
  IconArrowDown,
  IconLoader2,
  IconDownload,
  IconArrowUp,
  IconArrowsUpDown,
} from '@tabler/icons-react';
import {
  ColumnFiltersState,
  SortingState,
  VisibilityState,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { ChevronDown } from 'lucide-react';
import { useDebounce } from 'use-debounce';
import { Button } from '@/components/ui/button';
import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@/components/ui/table';
import useTranslation from '@/hooks/useTranslation';
import TranscriptsColumns from './TranscriptsColumns';
import { toast } from '@/components/ui/use-toast';
import { useMutateDownloadAllConversationsAsCSV } from '@/reactQuery/post';
import getUniqueMetadataKeys from '@/utils/getUniqueMetadataKeys';
import { Input } from '@/components/ui/input';
import replaceUnderscoreWithSpace from '@/utils/transformUnderscoreToSpace';
import calculateConversationDuration from '@/utils/calculateConversationDuration';
import formatDate from '@/utils/dateFormat';
import DataTablePagination from '@/components/paginations/DataTablePagination';
import { TableData } from '@/types';

export default function TranscriptsTable({ allConversations, project_id }: TableData) {
  const { lang } = useTranslation();
  const [sorting, setSorting] = useState<SortingState>([]);
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const [columnVisibility, setColumnVisibility] = useState<VisibilityState>({});
  const [rowSelection, setRowSelection] = useState<Record<string, boolean>>({});
  const { mutateAsync: mutateAsyncDownloadAllAsCSV, isPending: isDownloadingAllPending } =
    useMutateDownloadAllConversationsAsCSV();
  const [inputValue, setInputValue] = useState<Record<string, string>>({});
  const [debouncedInputValue] = useDebounce(inputValue, 400);

  const uniqueMetadataKeys = useMemo(() => {
    if (allConversations) {
      return getUniqueMetadataKeys(allConversations);
    }
    return new Set<string>();
  }, [allConversations]);
  // Convert Set to Array
  const metadataKeysArray = Array.from(uniqueMetadataKeys);

  const downloadAsCSVAllConversations = ({ csvContent }: { csvContent: string }) => {
    // Create a blob with the CSV content
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });

    // Create a link element
    const link = document.createElement('a');

    // Create a URL for the blob and set it as the href attribute
    const url = URL.createObjectURL(blob);
    link.setAttribute('href', url);

    // Set the download attribute with a file name
    link.setAttribute('download', 'conversations.csv');

    // Append the link to the document body
    document.body.appendChild(link);

    // Trigger the download by programmatically clicking the link
    link.click();

    // Clean up by removing the link and revoking the object URL
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  };

  const downlaodAllConversationsAsCSV = async () => {
    try {
      const result = await mutateAsyncDownloadAllAsCSV({ project_id });
      const csvContent = result.data;
      downloadAsCSVAllConversations({ csvContent });
      toast({ description: lang.get('msg.allConversationsSuccessfullyDownloaded') });
    } catch (err) {
      toast({ description: lang.get('msg.errorPleaseTryAgain'), variant: 'destructive' });
    }
  };

  const columns = TranscriptsColumns(metadataKeysArray);

  const conversationsWithDuration = useMemo(
    () =>
      allConversations?.map((conversation) => ({
        ...conversation,
        createdAt: formatDate(conversation.createdAt, 'DD/MMM/YYYY - hh:mm:ss A'),
        duration: calculateConversationDuration({
          startedAt: conversation.startedAt,
          finishedAt: conversation.finishedAt,
        }),
      })),
    [allConversations],
  );

  const table = useReactTable({
    data: conversationsWithDuration || [],
    columns,
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onColumnVisibilityChange: setColumnVisibility,
    onRowSelectionChange: setRowSelection,
    state: {
      sorting,
      columnFilters,
      columnVisibility,
      rowSelection,
    },
    initialState: {
      pagination: {
        pageSize: 20,
      },
    },
  });

  useEffect(() => {
    Object.entries(debouncedInputValue).reduce((acc, [columnId, filterValue]) => {
      table.getColumn(columnId)?.setFilterValue(filterValue);
      return acc;
    }, {});
  }, [debouncedInputValue, table]);

  const handleInputChange = useCallback((columnId: string, value: string) => {
    setInputValue((prev) => ({
      ...prev,
      [columnId]: value,
    }));
  }, []);

  if (!allConversations) return null;

  return (
    <div className="w-full">
      <div className="flex flex-wrap items-center w-full gap-4 pt-4 pb-2">
        <div className="flex items-center justify-end w-full gap-4 mb-2">
          <div className="flex items-center gap-2">
            <Button
              disabled={isDownloadingAllPending}
              variant="default"
              onClick={downlaodAllConversationsAsCSV}
              className="flex items-center gap-1"
            >
              {isDownloadingAllPending ? (
                <IconLoader2 className="animate-spin" size={18} />
              ) : (
                <IconDownload size={18} />
              )}
              {lang.get('msg.exportAll')}
            </Button>
            {/* column filters */}
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <Button variant="outline" className="ml-auto text-dark-text">
                  <IconAdjustmentsHorizontal size={16} className="mr-2" />
                  {lang.get('msg.view')}
                  <ChevronDown className="w-4 h-4 ml-4" />
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent align="end">
                {table
                  .getAllColumns()
                  .filter((column) => column.getCanHide())
                  .map((column) => (
                    <DropdownMenuCheckboxItem
                      key={column?.id}
                      className="capitalize"
                      checked={column.getIsVisible()}
                      onCheckedChange={(value) => column.toggleVisibility(!!value)}
                    >
                      {column?.id}
                    </DropdownMenuCheckboxItem>
                  ))}
              </DropdownMenuContent>
            </DropdownMenu>
          </div>
        </div>
      </div>
      <div className="border rounded-md text-dark-text">
        <Table>
          <TableHeader>
            {table?.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup?.id}>
                {headerGroup.headers.map((header) => (
                  <TableHead key={header?.id}>
                    <div className='flex items-center w-full gap-2"'>
                      {header.isPlaceholder
                        ? null
                        : flexRender(header.column.columnDef.header, header.getContext())}
                      {header?.id !== 'actions' && header?.id !== 'participants' && (
                        <div>
                          {{
                            asc: <IconArrowUp size={18} />,
                            desc: <IconArrowDown size={18} />,
                          }[header.column.getIsSorted() as string] ?? (
                            <IconArrowsUpDown size={18} />
                          )}
                        </div>
                      )}
                    </div>
                    {header?.id !== 'actions' && header?.id !== 'participants' && (
                      <Input
                        placeholder={`${lang.get('msg.search')} ${replaceUnderscoreWithSpace(
                          header?.id,
                        )}...`}
                        value={(inputValue[header.id] as string) || ''}
                        onChange={(e) => handleInputChange(header.id, e.target.value)}
                        className="w-full mb-2.5 min-w-52 max-w-52 bg-white"
                      />
                    )}
                  </TableHead>
                ))}
              </TableRow>
            ))}
          </TableHeader>
          <TableBody>
            {table?.getRowModel().rows?.length ? (
              table?.getRowModel().rows.map((row) => (
                <TableRow key={row?.id} data-state={row.getIsSelected() && 'selected'}>
                  {row.getVisibleCells().map((cell) => (
                    <TableCell className="py-0 h-[60px]" key={cell?.id}>
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </TableCell>
                  ))}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={columns?.length} className="h-24 text-center">
                  {lang.get('msg.noResults')}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
      <DataTablePagination table={table} />
    </div>
  );
}
