import { Button } from '@/components/ui/button';
import { TranscriptsColumnsButtonProps } from '@/types';

function TranscriptsColumnsHeaderButton({ text, onClick }: TranscriptsColumnsButtonProps) {
  return (
    <Button variant="ghost" className="pl-0" onClick={onClick}>
      {text}
    </Button>
  );
}

export default TranscriptsColumnsHeaderButton;
