interface IToneOfVoice {
  id: number;
  label: string;
  value: string;
}

const toneOfVoiceList: IToneOfVoice[] = [
  {
    id: 1,
    label: 'friendly',
    value: 'friendly',
  },
  {
    id: 2,
    label: 'formal',
    value: 'formal',
  },
  {
    id: 3,
    label: 'neutral',
    value: 'neutral',
  },
  {
    id: 4,
    label: 'empathetic',
    value: 'empathetic',
  },
  {
    id: 5,
    label: 'challenging',
    value: 'challenging',
  },
];

export default toneOfVoiceList;
