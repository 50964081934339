import React, { useState, useRef } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { IconLoader2 } from '@tabler/icons-react';
import ReactMarkdown from 'react-markdown';
import useTheme from 'chat-ui/src/hooks/useTheme';
import { v4 as uuidv4 } from 'uuid';
import { useQuery } from '@tanstack/react-query';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/components/ui/tooltip';
import useResultTabStore from '@/store/ResultsTabStore';
import axiosInstanceAnalyze from '@/axios/AxiosInstanceAnalyze';
import ConversationMessage from '@/components/chat/ConversationMessage';

interface ITextProps {
  text: string;
}

function TextWithClickableQuotes({ text }: ITextProps) {
  const { currentTheme } = useTheme();
  const { project_id } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const { setActiveTab } = useResultTabStore();

  const [isHovered, setIsHovered] = useState(false);
  const [hoveredConversationId, setHoveredConversationId] = useState('');
  const hoverTimeout = useRef<NodeJS.Timeout | null>(null);

  const { data: conversationData, isLoading } = useQuery<AllTranscriptsProps>({
    queryFn: () => axiosInstanceAnalyze
      .get(`/analyzer/results/${project_id}/get_conversations/${hoveredConversationId}`)
      .then((response) => response.data),
    queryKey: ['selected_conversation', { project_id, hoveredConversationId }],
    enabled: hoveredConversationId !== '',
  });

  const formattedConversationData = conversationData?.messages;

  const addQueryParams = (params: Record<string, string>) => {
    Object.entries(params).forEach(([key, value]) => {
      searchParams.set(key, value);
    });
    setSearchParams(searchParams);
  };

  const openClickedQuote = (id: string) => {
    setActiveTab('transcripts_tab');
    addQueryParams({ selected_transcript: id });
  };

  const handleMouseEnter = (conversationId: string) => {
    if (hoverTimeout.current) {
      clearTimeout(hoverTimeout.current);
    }
    setHoveredConversationId(conversationId);
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    hoverTimeout.current = setTimeout(() => {
      setHoveredConversationId('');
      setIsHovered(false);
    }, 200);
  };

  const parseText = (input: string) => {
    const regex = /<quote>\s*{([^}]*)}\s*<\/quote>/g;
    const result: React.ReactNode[] = [];
    let lastIndex = 0;
    let match: RegExpExecArray | null;

    // eslint-disable-next-line no-cond-assign
    while ((match = regex.exec(input)) !== null) {
      const before = input.slice(lastIndex, match.index);
      if (before) result.push(<ReactMarkdown key={uuidv4()} components={{ p: 'span' }}>{before}</ReactMarkdown>);

      const quoteContent = match[1];
      try {
        const sanitizedContent = `{${quoteContent
          .replace(/'/g, '"') // Replace single quotes with double quotes
          .replace(/([a-zA-Z_]+):/g, '"$1":') // Quote object keys
          .replace(/:\s*([a-zA-Z0-9-]+)/g, ': "$1"') // Wrap values with quotes
          .replace(/: undefined/g, ': null') // Replace `undefined` with `null`
        }}`;
        const quoteData = JSON.parse(sanitizedContent);

        result.push(
          <TooltipProvider delayDuration={0} key={uuidv4()}>
            <span
              onMouseEnter={() => handleMouseEnter(quoteData.conversationId)}
              onMouseLeave={handleMouseLeave}
              className="relative inline-block"
            >
              <Tooltip>
                <TooltipTrigger asChild>
                  <button
                    style={{ backgroundColor: currentTheme?.primary }}
                    type="button"
                    onClick={() => openClickedQuote(quoteData.conversationId)}
                    className="inline-flex items-center justify-center w-6 h-6 mx-1 font-semibold text-white rounded-full opacity-90"
                  >
                    {quoteData.respondent_index != null ? Number(quoteData.respondent_index) : '?'}
                  </button>
                </TooltipTrigger>
                {isHovered && (
                  <TooltipContent className="w-[350px] bg-white border-2 shadow h-[250px] mx-3 z-100">
                    {isLoading ? (
                      <div className="flex items-center justify-center w-full h-full">
                        <IconLoader2 size={32} className="m-auto animate-spin text-dark-text" />
                      </div>
                    ) : (
                      <div className="relative flex flex-col w-full h-full px-2 overflow-hidden overflow-y-auto">
                        <div className="flex flex-col w-full gap-1 pb-2 mb-2">
                          {formattedConversationData?.map((item) => (
                            <ConversationMessage key={item?._id} {...item} />
                          ))}
                        </div>
                      </div>
                    )}
                  </TooltipContent>
                )}
              </Tooltip>
            </span>
          </TooltipProvider>,
        );
      } catch (error) {
        console.error('Failed to parse quote content:', quoteContent, error);
      }

      lastIndex = regex.lastIndex;
    }

    const after = input.slice(lastIndex);
    if (after) result.push(<ReactMarkdown key={uuidv4()} components={{ p: 'span' }}>{after}</ReactMarkdown>);

    return result;
  };

  return <span>{parseText(text)}</span>;
}

export default TextWithClickableQuotes;
