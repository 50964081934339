import { TranscriptsColumnsButtonProps } from '@/types';

function TranscriptsColumnsCell({
  text,
  onClick,
  additionalClasses,
}: TranscriptsColumnsButtonProps) {
  return (
    <button type="button" onClick={onClick} className={`whitespace-nowrap ${additionalClasses}`}>
      <p>{text}</p>
    </button>
  );
}

export default TranscriptsColumnsCell;
