import { useParams, useSearchParams } from 'react-router-dom';
import { IconLoader2 } from '@tabler/icons-react';
import { useQuery } from '@tanstack/react-query';
import useTheme from 'chat-ui/src/hooks/useTheme';
import axiosInstanceAnalyze from '@/axios/AxiosInstanceAnalyze';
import FullPageLoader from '@/components/atoms/Loader/FullPageLoader';
import PaperAirplaneIcon from '@/components/icons/PaperAirplaneIcon';
import SummarySkeletonLoader from '@/components/loaders/SummarySkeletonLoader';
import { Button } from '@/components/ui/button';
import { EProjectStatus } from '@/enums';
import { cn } from '@/lib/utils';
import useReportStore from '@/store/ReportStore';
import Statistics from './overview_content/Statistics';
import {
  translateAddToReport,
  translateNoSummaryData,
  translateSummary,
  translateQuestions,
  translateNoQuestionsData,
  translateKeyThemes,
  translateNoKeyThemesData,
  translateInterestingQuotes,
  translateNoQuotesData,
  translateOpportunities,
  translateNoOpportunitiesData,
} from './project_results/resultsPageTranslations';
import QuestionAnswerAccordion from '@/components/projects/single-project/research-design/components/QuestionAnswerAccordion';
import DataWithSteps from '@/components/QuestionItem/DataWithSteps';
import InterestingQuotes from '@/components/QuestionItem/InterestingQuotes';
import useTranslation from '@/hooks/useTranslation';
import TextWithClickableQuotes from '@/utils/TextWithClickableQuotes';

interface IOverall {
  isPendingReRun: boolean;
  analysisLanguage: string;
  singleProjectData: IProjectProps;
  newConversationsCount: number;
  onReRun: () => void;
  isRerunLoading: boolean;
}

interface QuestionSummary {
  title: string;
  body: string;
  index: number;
}

function Overview({ isPendingReRun, analysisLanguage, singleProjectData, newConversationsCount, onReRun, isRerunLoading }: IOverall) {
  const { project_id } = useParams();
  const [searchParams] = useSearchParams();
  const filter_id = searchParams.get('filter_id');
  const { currentTheme } = useTheme();
  const { lang } = useTranslation();

  const { data: overviewData, isLoading: isOverviewLoading } = useQuery({
    queryFn: () => axiosInstanceAnalyze.get(`/analyzer/results/${project_id}/overall${filter_id ? `?filter_id=${filter_id}` : ''}`),
    queryKey: ['overall_data', { filter_id }],
    select: (data) => data?.data,
  });

  const questionSummary: QuestionSummary[] = overviewData?.question_summaries;
  const sortedQuestions = questionSummary?.every((item) => typeof item.index === 'number')
    ? questionSummary?.sort((a, b) => a.index - b.index)
    : questionSummary;

  const { isSummaryChecked, setIsSummaryChecked, reportData,
    setReportData, isQuestionsChecked, setIsQuestionsChecked, isKeyThemesChecked,
    setIsKeyThemesChecked, isQuotesChecked, setIsQuotesChecked, isOpportunitiesChecked,
    setIsOpportunitiesChecked } = useReportStore();

  const onReportSaveSummary = () => {
    setIsSummaryChecked(!isSummaryChecked);
    if (!isSummaryChecked) {
      setReportData({ ...reportData, summary: overviewData?.summary });
    } else {
      const { summary, ...restData } = reportData;
      setReportData(restData);
    }
  };

  const onReportSaveQuestions = () => {
    setIsQuestionsChecked(!isQuestionsChecked);
    if (!isQuestionsChecked) {
      setReportData({ ...reportData, question_summaries: questionSummary });
    } else {
      const { question_summaries, ...restData } = reportData;
      setReportData(restData);
    }
  };

  const onReportSaveKeyThemes = () => {
    setIsKeyThemesChecked(!isKeyThemesChecked);
    if (!isKeyThemesChecked) {
      setReportData({ ...reportData, themes: overviewData?.themes });
    } else {
      const { themes, ...restData } = reportData;
      setReportData(restData);
    }
  };

  const onReportSaveQuotes = () => {
    setIsQuotesChecked(!isQuotesChecked);
    if (!isQuotesChecked) {
      setReportData({ ...reportData, quotes: overviewData?.quotes });
    } else {
      const { quotes, ...restData } = reportData;
      setReportData(restData);
    }
  };
  const onReportSaveOpportunities = () => {
    setIsOpportunitiesChecked(!isOpportunitiesChecked);
    if (!isOpportunitiesChecked) {
      setReportData({ ...reportData, opportunities: overviewData?.opportunities });
    } else {
      const { opportunities, ...restData } = reportData;
      setReportData(restData);
    }
  };

  const noEnoughInterviewData = overviewData?.statistics === null
    && overviewData?.summary?.length === 0 && overviewData?.themes?.length === 0
    && overviewData?.quotes?.length === 0
    && overviewData?.opportunities?.length === 0;

  const isQuestionsDataExists = !!questionSummary?.length;

  if (isOverviewLoading) return <FullPageLoader />;

  return (
    !noEnoughInterviewData ? (
      <div
        style={{ backgroundColor: currentTheme?.background }}
        className="flex flex-col items-center justify-center w-full gap-16"
      >
        <Statistics showSelect data={overviewData?.statistics} isLoading={isPendingReRun || isOverviewLoading} project_id={project_id} />
        {/* summary */}
        <div className="flex flex-col items-center justify-center w-full">
          <div className="flex items-start justify-between w-full">
            <h3
              style={{ color: currentTheme?.primary }}
              className="!mb-6 text-lg font-bold epilogue-font"
            >
              {translateSummary[analysisLanguage] || translateSummary.English}
            </h3>
            {overviewData?.summary?.length !== 0 && (!isPendingReRun && !isOverviewLoading) && (
              <div className="flex items-center gap-3">
                <p className="font-medium">{translateAddToReport[analysisLanguage] || translateAddToReport.English}</p>
                <div className="flex items-center justify-center w-5 h-5 border p-0.5 border-black rounded-full">
                  <button
                    onClick={() => {
                      onReportSaveSummary();
                    }}
                    style={{ backgroundColor: isSummaryChecked ? currentTheme?.primary : '#FFFFFF' }}
                    type="button"
                    className={cn('w-full h-full rounded-full')}
                  >
                  </button>
                </div>
              </div>
            )}
          </div>
          {overviewData?.summary?.length !== 0 ? (
            <div className="w-full ">
              {isPendingReRun || isOverviewLoading
                ? <SummarySkeletonLoader />
                : <TextWithClickableQuotes text={overviewData?.summary} />}
            </div>
          ) : <p className="w-full text-left text-[15px]  mx-auto">{translateNoSummaryData[analysisLanguage] || translateNoSummaryData.English}</p>}
        </div>

        {/* questions */}
        {isQuestionsDataExists && (
          <div className="flex flex-col w-full">
            <div className="flex items-center justify-between w-full">
              <h3
                style={{ color: currentTheme?.primary }}
                className="!mb-6 text-lg font-bold epilogue-font"
              >
                {translateQuestions[analysisLanguage] || translateQuestions.English}
              </h3>
              {questionSummary?.length !== 0 && (!isPendingReRun && !isOverviewLoading) && (
                <div className="flex items-center gap-3">
                  <p className="font-medium">{translateAddToReport[analysisLanguage] || translateAddToReport.English}</p>
                  <div className="flex items-center justify-center w-5 h-5 border p-0.5 border-black rounded-full">
                    <button
                      onClick={() => {
                        onReportSaveQuestions();
                      }}
                      style={{ backgroundColor: isQuestionsChecked ? currentTheme?.primary : '#FFFFFF' }}
                      type="button"
                      className={cn('w-full h-full rounded-full')}
                    >
                    </button>
                  </div>
                </div>
              )}
            </div>
            {(questionSummary?.length !== 0 && !!questionSummary)
              ? <QuestionAnswerAccordion data={sortedQuestions} />
              : <p className="w-full text-left text-[15px]  mx-auto">{translateNoQuestionsData[analysisLanguage] || translateNoQuestionsData.English}</p>}
          </div>
        )}

        {/* -- OLD OVERVIEW DATA -- */}
        {!isQuestionsDataExists && (
          <div className="flex flex-col items-center justify-center w-full gap-16">
            {/* key themes */}
            <div className="flex flex-col items-center justify-center w-full">
              <div className="flex items-start justify-between w-full ">
                <h3
                  style={{ color: currentTheme?.primary }}
                  className="!mb-6 text-lg font-bold epilogue-font"
                >
                  {translateKeyThemes[analysisLanguage] || translateKeyThemes.English}
                </h3>
                {overviewData?.themes?.length !== 0 && (!isPendingReRun && !isOverviewLoading) && (
                  <div className="flex items-center gap-3">
                    <p className="font-medium">{translateAddToReport[analysisLanguage] || translateAddToReport.English}</p>
                    <div className="flex items-center justify-center w-5 h-5 border p-0.5 border-black rounded-full">
                      <button
                        onClick={() => {
                          onReportSaveKeyThemes();
                        }}
                        style={{ backgroundColor: isKeyThemesChecked ? currentTheme?.primary : '#FFFFFF' }}
                        type="button"
                        className={cn('w-full h-full rounded-full')}
                      >
                      </button>
                    </div>
                  </div>
                )}
              </div>
              {overviewData?.themes?.length !== 0
                ? <DataWithSteps data={overviewData?.themes} isLoading={isPendingReRun || isOverviewLoading} />
                : <p className="w-full text-left text-[15px]  mx-auto">{translateNoKeyThemesData[analysisLanguage] || translateNoKeyThemesData.English}</p>}
            </div>
            {/* quotes */}
            <div className="flex flex-col items-center justify-center w-full">
              <div className="flex items-start justify-between w-full ">
                <h3
                  style={{ color: currentTheme?.primary }}
                  className="!mb-6 text-lg font-bold epilogue-font"
                >
                  {translateInterestingQuotes[analysisLanguage] || translateInterestingQuotes.English}
                </h3>
                {overviewData?.quotes?.length !== 0 && (!isPendingReRun && !isOverviewLoading) && (
                  <div className="flex items-center gap-3">
                    <p className="font-medium">{translateAddToReport[analysisLanguage] || translateAddToReport.English}</p>
                    <div className="flex items-center justify-center w-5 h-5 border p-0.5 border-black rounded-full">
                      <button
                        onClick={() => {
                          onReportSaveQuotes();
                        }}
                        style={{ backgroundColor: isQuotesChecked ? currentTheme?.primary : '#FFFFFF' }}
                        type="button"
                        className={cn('w-full h-full rounded-full')}
                      >
                      </button>
                    </div>
                  </div>
                )}
              </div>
              <div className="flex items-center justify-center w-full">
                {overviewData?.quotes?.length !== 0
                  ? <InterestingQuotes analysisLanguage={analysisLanguage} data={overviewData?.quotes} isLoading={isPendingReRun || isOverviewLoading} />
                  : <p className="w-full text-left text-[15px]  mx-auto">{translateNoQuotesData[analysisLanguage] || translateNoQuotesData.English}</p>}
              </div>
            </div>

            {/* opportunities */}
            <div className="flex flex-col items-center justify-center w-full">
              <div className="flex items-start justify-between w-full ">
                <h3
                  style={{ color: currentTheme?.primary }}
                  className="!mb-6 text-lg font-bold epilogue-font"
                >
                  {translateOpportunities[analysisLanguage] || translateOpportunities.English}
                </h3>
                {overviewData?.opportunities?.length !== 0 && (!isPendingReRun && !isOverviewLoading) && (
                  <div className="flex items-center gap-3">
                    <p className="font-medium">{translateAddToReport[analysisLanguage] || translateAddToReport.English}</p>
                    <div className="flex items-center justify-center w-5 h-5 border p-0.5 border-black rounded-full">
                      <button
                        onClick={() => {
                          onReportSaveOpportunities();
                        }}
                        style={{ backgroundColor: isOpportunitiesChecked ? currentTheme?.primary : '#FFFFFF' }}
                        type="button"
                        className={cn('w-full h-full rounded-full')}
                      >
                      </button>
                    </div>
                  </div>
                )}
              </div>
              {overviewData?.opportunities?.length !== 0
                ? <DataWithSteps data={overviewData?.opportunities} isLoading={isPendingReRun || isOverviewLoading} />
                : <p className="w-full text-left text-[15px]  mx-auto">{translateNoOpportunitiesData[analysisLanguage] || translateNoOpportunitiesData.English}</p>}
            </div>
          </div>
        )}
      </div>
    ) : (
      <div className="flex flex-col items-center justify-center w-full h-full min-h-[65vh] text-center">
        {singleProjectData?.status !== EProjectStatus.COMPLETED && (
          <Button
            variant="default"
            disabled={isPendingReRun || isRerunLoading}
            className="relative flex items-center gap-2 mt-5 font-bold px-7"
            onClick={() => onReRun()}
          >
            {
              (isPendingReRun || isRerunLoading)
                ? <IconLoader2 className="animate-spin" size={20} />
                : <PaperAirplaneIcon className="size-4" />
            }
            {isPendingReRun || isRerunLoading ? 'Running...' : 'Run'}
            {!isRerunLoading && newConversationsCount > 0 && (
              <span className="absolute flex items-center justify-center h-5 px-1.5 text-xs text-white bg-red-600 rounded-full w-fit -top-1 -right-1.5">
                {newConversationsCount}
              </span>
            )}
          </Button>
        )}
        {singleProjectData?.status === EProjectStatus.COMPLETED && isRerunLoading && (
          <Button
            variant="default"
            disabled
            className="flex items-center gap-2 mr-[4%] font-bold px-7"
          >
            <IconLoader2 className="animate-spin" size={20} />
            <p className="epilogue-font">{lang.get('msg.applyingFilter')}</p>
          </Button>
        )}
        {(singleProjectData?.status === EProjectStatus.COMPLETED && !!filter_id && overviewData?.statistics === null && !isRerunLoading)
          && <p className="font-medium">{lang.get('msg.noInterviewsThatMatchThisFilter')}</p>}
      </div>
    )
  );
}

export default Overview;
